<template>
    <div>


        <v-container id="user-profile" fluid tag="section" class="grey lighten-5 mb-1 mt-10" :loading=loading
            loading-text="Loading... Please wait">
            <v-row justify="center">
                <v-col cols="12" md="12">
                    <v-card>
                        <v-card id="head-info">
                            <v-row>
                                <v-col cols="12" md="8">
                                    <v-chip close-icon="mdi-close-outline">{{ SubscriptionPackage.packageName }} Package
                                    </v-chip>
                                    <v-chip close-icon="mdi-close-outline"
                                        v-if="SubscriptionPackage.subscriptionPeriod == 1"> Quarter </v-chip>
                                    <v-chip close-icon="mdi-close-outline"
                                        v-if="SubscriptionPackage.subscriptionPeriod == 2"> Semi-Annual</v-chip>
                                    <v-chip close-icon="mdi-close-outline"
                                        v-if="SubscriptionPackage.subscriptionPeriod == 3"> Annual</v-chip>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="8">
                                    <div class="font-size-h1 font-weight-light text-white">
                                        Company Profile
                                    </div>
                                    <div class="text-subtitle-1 font-weight-light text-white">
                                        Complete your profile
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-app>
                        <v-form v-model="valid">
                            <v-container class="py-0">
                                <v-row>
                                    <v-col cols="12" md="4">
                                        <v-text-field id="LegalName" class="green-input" v-model="form.legalName"
                                            label="Legal Name" :rules="[rules.required]" type="text" />
                                    </v-col>

                                    <v-col cols="12" md="4">
                                        <v-text-field id="CommercialName" class="green-input" v-model="form.commercialName"
                                            label="Commercial name" :rules="[rules.required]" type="text" />
                                    </v-col>

                                    <!-- <v-col cols="12" md="4">
                                        <v-select v-model="form.legalStructure" :items="legalStructureOptions"
                                            :rules="[v => !!v || 'Item is required']" label="Legal Structure"
                                            item-text="englishName" item-value="id" required>
                                        </v-select>
                                    </v-col> -->

                                    <v-col cols="6" md="4">
                                        <v-text-field id="txtYearOfEstablishment" class="green-input"
                                            v-model="form.yearOfEstablishment" label="Establishment Year"
                                            :rules="[rules.required, rules.yearRule]" type="number" />
                                    </v-col>
                                    <v-col cols="6" md="8">
                                        <v-text-field id="txtWebSite" class="green-input" v-model="form.webSite"
                                            label="Website" placeholder="N/A" type="text" />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4" md="4">
                                        <v-select v-model="form.industryType" :items="industryTypeOptions"
                                            :rules="[v => !!v || 'Item is required']" label="Industry Type"
                                            item-text="englishName" item-value="id" @change="industryTypeChanged" required>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="4" md="4">
                                        <v-select v-model="form.businessSector" :items="businessSectorsOptions"
                                            :rules="[v => !!v || 'Item is required']" label="Business Sector"
                                            item-text="englishName" item-value="id" :disabled="form.industryType == null"
                                            @change="businessSectorChanged" required>
                                        </v-select>
                                    </v-col>

                                    <v-col cols="4" md="4">
                                        <v-select v-model="form.businessSubSector" :items="businessSubSectorsOptions"
                                            :rules="[v => !!v || 'Item is required']" label="Sub-Sector"
                                            item-text="englishName" item-value="id" :disabled="form.businessSector == null"
                                            required>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4" md="4">
                                        <v-text-field id="txtContactPerson" class="green-input" v-model="form.contactPerson"
                                            label="Contact Person" :rules="[rules.required]" type="text" />
                                    </v-col>

                                    <v-col cols="4" md="4">
                                        <v-text-field id="txtContactPersonMail" class="green-input"
                                            v-model="form.contactPersonMail" label="Contact Person Mail"
                                            :rules="[rules.required, rules.emailRule]" type="text" />
                                    </v-col>

                                    <v-col cols="4" md="4">
                                        <v-text-field id="txtContactPersonMobile" class="green-input"
                                            v-model="form.contactPersonMobile" label="Contact Person Mobile"
                                            :rules="[rules.required]" type="text" />
                                    </v-col>

                                    <v-col cols="12" class="text-right">
                                        <v-btn color="success" class="mr-0" :disabled="!valid" @click="SaveProfile">
                                            Submit Reqeust
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-app>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="successfullRegDialog" max-width="500px">
            <v-card>
                <v-card-title class="text-h3">Registeration coompleted, you will be notified once the account is created
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="RegisterationConfirmed">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>


    </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, minLength, between } from "vuelidate/lib/validators";
export default {
    mixins: [validationMixin],
    // props: ['package','packagePeriod'],
    computed: {
        SubscriptionPackage: function () {
            return this.$store.state.SubscriptionRequestPackage
        }
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    data() {
        return {
            valid: true,
            successfullRegDialog: false,
            rules: {
                required: value => !!value || 'Required Field',
                yearRule: value => value >= 1950 && value <= 2021 || 'Year should be between 1950 and 2021',
                emailRule: v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) || 'E-mail must be valid',
            },
            currentSelectedCustomer: null,
            loading: false,
            btnSubmitEnabled: false,
            form: {
                id: 0,
                legalName: null,
                commercialName: null,
                legalStructure: null,
                yearOfEstablishment: null,
                industryType: null,
                businessSector: null,
                businessSubSector: null,
                contactPerson: null,
                contactPersonMail: null,
                contactPersonMobile: null,
                webSite: null,
            },
            //legalStructureOptions: [],
            industryTypeOptions: [],
            businessSectorsAllOptions: [],
            businessSectorsOptions: [],
            businessSubSectorsAllOptions: [],
            businessSubSectorsOptions: [],
        }
    },
    validations: {
        form: {
            legalName: {
                required,
            },
            commercialName: {
                required,
            },
            legalStructure: {
                required
            },
            yearOfEstablishment: {
                required,
                minLength: minLength(4),
                between: between(1980, (new Date()).getFullYear())
            },
            industryType: {
                required
            },
            businessSector: {
                required
            },
            businessSubSector: {
                required
            },
            contactPerson: {
                required
            },
            contactPersonMail: {
                required
            },
            contactPersonMobile: {
                required
            },
        }
    },
    methods: {
        industryTypeChanged(item) {


            this.loading = true;

            if (this.businessSectorsAllOptions == null ||
                (this.businessSectorsAllOptions != null && this.businessSectorsAllOptions.filter(b => b.industryTypeId == item).length == 0)
            ) {
                console.log('item not found so fetching for it')
                this.$axios({
                    method: 'get',
                    url: `${this.$baseURL}/BusinessSector/filter`,
                    params: {
                        industryTypeId: item
                    }
                })
                    .then((response) => {



                        let w = [];
                        response.data.result.forEach((businessSector) => {

                            w.push({
                                id: businessSector.id,
                                industryTypeId: businessSector.industryTypeId,
                                englishName: businessSector.englishName,
                                arabicName: businessSector.arabicName,
                                status: businessSector.status
                            });
                        });


                        if (this.businessSectorsAllOptions == null) {
                            this.businessSectorsAllOptions = w;
                        }
                        else {
                            this.businessSectorsAllOptions = this.businessSectorsAllOptions.concat(w);
                        }
                        console.log('businessSectorsAllOptions')
                        console.log(this.businessSectorsAllOptions)
                        this.businessSectorsOptions = this.businessSectorsAllOptions.filter(b => b.industryTypeId == item);
                        this.loading = false;

                    });

            }
            else {
                this.businessSectorsOptions = this.businessSectorsAllOptions.filter(b => b.industryTypeId == item);
                this.loading = false;
            }








        },
        businessSectorChanged(item) {

            if (this.businessSubSectorsAllOptions == null ||
                (this.businessSubSectorsAllOptions != null && this.businessSubSectorsAllOptions.filter(b => b.businessSectorId == item).length == 0)
            ) {

                this.$axios({
                    method: 'get',
                    url: `${this.$baseURL}/BusinessSubSector/filter`,
                    params: {
                        businessSectorId: item
                    }
                })
                    .then((response) => {

                        console.log('response...........................')
                        console.log(response)

                        let w = [];
                        response.data.result.forEach((businessSubSector) => {

                            w.push({
                                id: businessSubSector.id,
                                businessSectorId: businessSubSector.businessSectorId,
                                englishName: businessSubSector.englishName,
                                arabicName: businessSubSector.arabicName,
                                status: businessSubSector.status
                            });
                        });


                        if (this.businessSubSectorsAllOptions == null) {
                            this.businessSubSectorsAllOptions = w;
                        }
                        else {
                            this.businessSubSectorsAllOptions = this.businessSubSectorsAllOptions.concat(w);
                        }

                        this.businessSubSectorsOptions = this.businessSubSectorsAllOptions.filter(b => b.businessSectorId == item);

                        console.log(' this.businessSubSectorsOptions')
                        console.log(this.businessSubSectorsOptions)

                    });

            }
            else {
                this.businessSubSectorsOptions = this.businessSubSectorsAllOptions.filter(b => b.businessSectorId == item);
            }

        },
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        SaveProfile() {
            // console.log(this.SubscriptionPackage);
            this.valid = false;
            this.$axios({
                method: 'post',
                url: `${this.$baseURL}/Account/CustomerRegisteration`,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    LegalName: this.form.legalName,
                    CommercialName: this.form.commercialName,
                    LegalStructureId: this.form.legalStructure,
                    YearOfEstablishment: Number(this.form.yearOfEstablishment),
                    Website: this.form.webSite,
                    IndustryTypeId: this.form.industryType,
                    BusinessSectorId: this.form.businessSector,
                    BusinessSubSectorId: this.form.businessSubSector,
                    ContactPersonName: this.form.contactPerson,
                    ContactPersonEMail: this.form.contactPersonMail,
                    ContactPersonTelNumber: this.form.contactPersonMobile,
                    PackageId: this.SubscriptionPackage.id,
                    subscriptionPeriod: this.SubscriptionPackage.subscriptionPeriod,
                    SubscriptionFees: this.SubscriptionPackage.SubscriptionFees

                }
            }).then(() => {



                // this.form.id = response.data;
                // this.successfullRegDialog = true

                this.$store.dispatch("registerationcomplete");
                this.$router.push('registerationcomplete');

            });
        },
        getRegisterationLookups() {
            this.$axios({
                method: 'get',
                url: `${this.$baseURL}/CustomerProfile/lookups`,
            })
                .then((response) => {
                    //this.legalStructureOptions = response.data.legalStructures;
                    this.industryTypeOptions = response.data.industryTypes;
                    this.businessSectorsAllOptions = response.data.businessSectors;
                    this.businessSubSectorsAllOptions = response.data.businessSubSectors;
                });
        },
        RegisterationConfirmed() {
            this.successfullRegDialog = false
            this.$router.push('register');
        }
    },
    mounted() {
        // console.log(this.SubscriptionPackage.SubscriptionFees)
        this.getRegisterationLookups();
    }
}
</script>

<style>
#head-info{background-color: rgb(13, 165, 13);padding: 20px;box-shadow: 5px 5px 5px solid black;}

</style>